import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import secondaryImg from "../images/primary-coaches-sm.jpg"
import secondaryImgMob from "../images/primary-coaches-sm-mobile.jpg"
import secondaryImgWebp from "../images/primary-coaches-sm.webp"
import secondaryImgMobWebp from "../images/primary-coaches-sm-mobile.webp"
import coachServicesImg from "../images/coach-hire-services-sm.jpg"
import smileSvg from "../images/smile-beam-regular.svg"
import starSvg from "../images/star-solid.svg"
import arrowSvg from "../images/arrow-circle-right-solid.svg"
import NewsStrip from "../components/news-strip";
import AOS from "aos"
import 'aos/dist/aos.css';

class CoachHireBradfordPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Coach Hire Bradford" description="Established in 1949, Tetley's Coaches is one of Yorkshire's oldest coach companies and has provided safe and reliable coach transport in Bradford and beyond for over 75 years." />
        <div className="landing-hero-bradford">
          <div className="landing-hero__inner">
            <div className="landing-hero__inner__headline">
              <p className="landing-hero__inner__primary-headline">Reliable, affordable and local</p>
              <p className="landing-hero__inner__secondary-headline">Bus and coach hire since 1949</p>
            </div>
          </div>
        </div>

        <div className="block-about-us body-bg">
          <div className="body">
            <div className="block-about-us__container">
              <h1>Coach Hire Bradford</h1>
              <h2>Providing Coach Hire for over 75 years</h2>
              <div className="gutter block-about-us__content-parent">
                <div className="block-about-us__content-left">
                  <picture className="block-about-us__hero">
                    <source media="(max-width: 499px)" type="image/webp" srcSet={secondaryImgMobWebp} />
                    <source media="(max-width: 499px)" type="image/jpg" srcSet={secondaryImgMob} />
                    <source media="(min-width: 500px)" type="image/webp" srcSet={secondaryImgWebp} />
                    <source media="(min-width: 500px)" type="image/jpg" srcSet={secondaryImg} />
                    <img src={secondaryImg} alt="Coach hire Bradford" />
                  </picture>
                </div>
                <div className="block-about-us__content-right">
                  <p>We have a large customer base in Bradford serving many of the local schools, clubs and pubs in the area. Providing a high-quality service which is reliable and affordable we are proud of the repeat custom we get which is testament to all our hard work.</p>
                  <p>Some of our long-standing customers such as the Bradford City supporters, Bradford University and many of the local primary schools reaffirm our claim of being the number one operator in the region.</p>
                </div>

              </div>
              <p style={{ textAlign: 'center' }}>
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="block-services">
          <div className="body gutter">
            <div className='block-services__heading' data-aos='fade-left' data-aos-duration="300">
              <h2>Coach hire quote</h2>
              <h3>Coach hire for any occasion</h3>
            </div>
            <div className="block-services__primary">

              <div className="block-services__primary-text">
                <p>Why not explore the city of Bradford by visiting local attractions such as The Alhambra Theatre, Science and Media Museum and the Bronte Parsonage Museum. We can tailor your quote to fit your requirements.</p>
                <p>Whether by email, phone call (0113 2762276) or filling in our <a href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">online quote form</a>, our friendly and experienced sales team are on hand ready to help.</p>
              </div>

              <div className="block-services__primary-img">
                <img src={coachServicesImg} alt="" />
              </div>

            </div>

            <div className="block-services__info" data-aos="fade-right" data-aos-duration="600">
              <img className="block-services__info-icon" src={smileSvg} alt='' />
              <div className="block-services__info-text">
                <h4>Unbeaten value</h4>
                <p>If we provide a quote and you can find a similar quality coach cheaper we'll do our best to beat it.</p>
              </div>
            </div>
            <div id="services" className="block-services__list-parent">
              <ul className="block-services__list">
                <li><Link to="/coach-hire-services/airport-transfers/" title="Coach Hire For Airport Transfers">Airport Transfers</Link></li>
                <li><Link to="/coach-hire-services/club-pub-trips/" title="Coach Hire For Club and Pub Trips">Club and Pub Trips</Link></li>
                <li><Link to="/coach-hire-services/coast-country-day-trips/" title="Coach Hire For Coast and Country Day Trips">Coast and Country Day Trips</Link></li>
                <li><Link to="/coach-hire-services/college-university-trips/" title="Coach Hire For College and University Trips">College and University Trips</Link></li>
                <li><Link to="/coach-hire-services/community-groups/" title="Coach Hire For Community Groups">Community Groups</Link></li>
                <li><Link to="/coach-hire-services/contract-coach-hire/" title="Coach Hire For Contract Work">Contract Work</Link></li>
                <li><Link to="/coach-hire-services/corporate-events-coach-hire/" title="Coach Hire For Corporate and Events">Corporate and Events</Link></li>
                <li><Link to="/coach-hire-services/day-at-the-races/" title="Coach Hire For A Day At The Races">Day At The Races</Link></li>
              </ul>
            </div>
            <div className="block-services__list-parent">
              <ul className="block-services__list">
                <li><Link to="/coach-hire-services/wheelchair-disabled-access-coach-hire/" title="Wheelchair friendly and disabled access coach hire">Disabled access coach hire</Link></li>
                <li><Link to="/coach-hire-services/funerals/" title="Coach Hire For Funerals">Funerals</Link></li>
                <li><Link to="/coach-hire-services/group-tours/" title="Coach Hire For Group Tours">Group Tours</Link></li>
                <li><Link to="/coach-hire-services/school-trips/" title="Coach Hire For School Trips">School Trips</Link></li>
                <li><Link to="/coach-hire-services/sporting-events/" title="Coach Hire For Sporting Events">Sporting Events</Link></li>
                <li><Link to="/coach-hire-services/theatre-concerts-cultural-events/" title="Coach Hire For Theatre, Concerts &amp; Cultural Events">Theatre, Concerts &amp; Cultural Events</Link></li>
                <li><Link to="/coach-hire-services/theme-parks/" title="Coach Hire For Theme Parks">Theme Parks</Link></li>
                <li><Link to="/coach-hire-services/weddings-engagements-parties-nights-out/" title="Coach Hire For Weddings, Engagements, Parties and Nights Out">Weddings, Engagements, Parties and Nights Out</Link></li>
              </ul>
            </div>

          </div>
        </div>

        <div className="block-review">
          <div className="body gutter">
            <h2>Reviews</h2>
            <div className="reviews">

              <div className="review" data-aos='fade-up' data-aos-delay="200" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">This is to let you know that David McDonald was outstanding during our trip.</p>
                <p className="review-quote">We have been doing this for last 14 years, never had anyone better. We will look forward to seeing him again in the future.</p>
                <p className="review-quote">Thanks again.</p>
                <p className="review-author">Abhay, Bradford</p>
              </div>

              <div className="review" data-aos='fade-up' data-aos-delay="100" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">Just wanted to say thank you to your company and to your driver.</p>
                <p className="review-quote">Sunday went really well with no hiccups and to top it off we won the final <span role="img" aria-label="Smiley face">😊</span>.</p>
                <p className="review-quote">When we are in need a coach in the future, I will be in contact again.</p>
                <p className="review-author">Tina, Bradford</p>
              </div>

              <div className="review" data-aos='fade-up' data-aos-delay="300" data-aos-duration="300">
                <p className="review-rating">
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="200" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="300" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="400" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="500" className='star' src={starSvg} alt='Star' />
                  <img data-aos="zoom-in" data-aos-duration="300" data-aos-delay="600" className='star' src={starSvg} alt='Star' />
                </p>
                <p className="review-quote">A colleague contacted me last week with a lovely email updating me about how lovely John, your driver, is.</p>
                <p className="review-quote">He is so polite, friendly and helpful at all times and we wanted to say a big thank you to him for all his hard work.</p>
                <p className="review-author">Anna, Bradford</p>
              </div>
            </div>
            <p style={{ margin: 0 }}>
              <a className='btn btn-review btn-grow' href="https://www.google.com/search?q=tetleys+coaches&ie=UTF-8#lrd=0x48795c38882c4475:0x9fc9a7dfa886a150,1,,," target="_blank" rel="noopener noreferrer">
                <span className='btn-review__text'>More reviews</span>
                <img className='btn-review__icon' src={arrowSvg} alt="" />
              </a>
            </p>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default CoachHireBradfordPage
